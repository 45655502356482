import React, { useEffect, useState } from 'react';
import queryString from "query-string";
import Counter from './Counter'
import Done from './Done';
import axios from 'axios';

export default function Landing({Play}:any) {
   const [url,setUrl]=useState("");
   const [done,setDone]=useState("");
  
   const queryParams = queryString.parse(window.location.search);
   useEffect(()=>{
    axios.post(
      "https://qrs-test.herokuapp.com/usershit"
     ,{
      // @ts-ignore
      id:queryParams.q,
     },
     )
   .then((res:any) => {
    //@ts-ignore. @ts-expect-error
     setUrl(res.data.linko)
     setDone("true")
     }).catch((err:any) => {
       setDone("false")
     });
   // eslint-disable-next-line react-hooks/exhaustive-deps   
   },[])
   if (done==="false"){
     return (
      <Done text="sorry Url Not Available" ></Done>
     )
  }else if (done==="true"){
  return (
    <>
    <div className="center">
          <Counter play={Play} link={url} />
          <p>just 5 secs</p>
          <p className='out' > <p>dont speak JUST make em scan it </p></p>
      </div>
      <div className='center2' >
      <h1 className='link'>at www.theqrs.store</h1>
          <p>...get your code now and join us...</p>
          <p> Link to :</p>
          <p> a specific second in a song</p>
          <p> a book that you love</p>
          <p>a quote</p>
          <p>social media</p>
          <p>or whatever you have in mind</p>
          
          
      </div>
      <button><a href="https://theqrs.store/" >welcome</a></button>
    </>
  )
  }
}
