import axios from 'axios'
import React, { useEffect, useState,useCallback } from 'react'
import Done from '../Done';
import {useAuthUser} from 'react-auth-kit'
export default function Url() {
  const auth = useAuthUser()
  const [changable,setChangable]=useState(0)
  const [str,setStr]=useState("");
  const [url,setUrl]=useState("");
  const [done,setDone]=useState("");
  
  const pay= useCallback(async()=>{
     try{
       axios.post(
        "https://qrs-test.herokuapp.com/create-checkout-session"
       ,{
        // @ts-ignore
         id:auth().id,
       },{
        headers:{
          // @ts-ignore
          token:`${auth().token}`
        }
       }
       )
     .then((res:any) => {
      
       setStr(res.data.url)
       return true
       }).catch((err:any) => {
         return false
       });
     }catch(err:any){
   
      return false 
    
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  const urler=async(e:any)=>{
           setUrl(e.target.value)
  }
  const getdetails= useCallback(async()=>{
       try{
        axios.post(
                "https://qrs-test.herokuapp.com/usershit"
               ,{
                // @ts-ignore
                id:auth().id,
               }
               )
             .then((res:any) => {
               setChangable(res.data.changable)
               return true
               }).catch((err:any) => {
                 return false
               });
       }catch(err:any){
        
        return false
       }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
 const onSubmit =async(event:any)=>{
    event.preventDefault();
    if(changable<=0){
     return alert("G, U need more tokens");
    }else{
      axios.post(
        "https://qrs-test.herokuapp.com/changeurl"
       ,{
        // @ts-ignore
        id:auth().id,
        url,
       },{
        headers:{
          // @ts-ignore
          token:`${auth().token}`
        }
       }
       )
     .then((res:any) => {
      
       setUrl("")
       setDone("true")
       return true
       }).catch((err:any) => {
         console.error(err);
         setDone("false")
         return false
       });
    }
   
  }
  useEffect(()=>{
    getdetails()
    pay()
// eslint-disable-next-line react-hooks/exhaustive-deps
},[])

   if(done==="true"){
    setTimeout(()=>{
      window.location.assign("/")
    },3000)
        return (
          <Done text="changed"></Done>
        )
   }else if(done==="false"){   
      return (
        <Done text="Change Failed" ></Done>
      )  
  }
   else{
    return (
      <form className='login' onSubmit={onSubmit} >
      <h1>Change url:</h1> 
      <div className='center'>
         <div className='horizental'>
            <label htmlFor="url">Tokens:</label>
          <button className='plus' onClick={pay}><a href={`${str}`} >+</a></button> 
         </div>
         <p>{changable}</p>
      </div>
       
       <div>
       <label htmlFor="url">url:</label>
       <br></br>
       <input type="url" id="url"
       placeholder="url"
       value={url}
       onChange={urler}
       required
       />
       </div>
       <br></br>
       <button type="submit" >Change</button>
      </form>
    )
   }
}
