import {useState,useEffect} from 'react'
export default function Counter({link,play}:any) {
    const [secs,setCounter] = useState(0);
    useEffect(() => {
        const interval = setInterval(() => setCounter(s=>s+1), 1500);
        if(secs>=5){
            window.location.assign(link);
            return () => clearInterval(interval);
        }
         return () => clearInterval(interval);
      }, [secs,link]);
  
    return(
         <div>5/{`${secs}`}</div>    
         )
}
