

//@ts-ignore. @ts-expect-error
import Logform from './forms/Logform';
import Change from './Change';
import Url from './forms/Url';
import Contact from './forms/Contact';
import { ErrorBoundary } from "react-error-boundary";
import Links from './Links';
import {Routes,Route,} from 'react-router-dom';
import Landing from './Landing';
import Done from './Done';
import {RequireAuth} from "react-auth-kit" 



export default function Main() {
  const audioTune = new Audio('../statics/audio.mp3') ;
  const playSound = () => {
    audioTune.play();
  }
// useEffect(() => {
//     audioTune.load();
//     audioTune.play()
//   })
  return (
  <div className='main'>
  <div className="overlay">  
    <div className="content">
    <div className="firstshit" >
  <ErrorBoundary fallback={<Done text=" sorry internal server error !" ></Done>} >
      <Routes>
      {/*@ts-ignore. @ts-expect-error*/}
      <Route  path='/landing'  Component={()=><Landing Play={playSound} ></Landing>} />
      <Route path='/log' Component={Logform} />
      <Route  path='/url' Component={()=><RequireAuth loginPath='/log' ><Url/></RequireAuth>} />
      <Route path='/contact' Component={()=><RequireAuth loginPath='/log' ><Contact/></RequireAuth>} />
      <Route path='/' Component={()=><RequireAuth loginPath='/log' ><Change/></RequireAuth>} />
      <Route path='/links' Component={()=><RequireAuth loginPath='/log' ><Links/></RequireAuth>} />
      <Route path='/changed' Component={()=>  <Done text=" Changed !" ></Done>} />
      <Route path='/nolink' Component={()=>  <Done text="Qr still has no link " btn={true} link="/"></Done>} />
      <Route path='/sent' Component={()=>  <Done text="sent" btn={true} link="/"></Done>} />
      <Route path="*" Component={()=><Done text=" sorry url not found !" ></Done>} />
      </Routes>
      {/* <Contact></Contact> */}
      {/* <Url></Url> */}
      {/* <Change></Change> */}
      {/* <Logform></Logform> */}
      </ErrorBoundary>   
     </div>
            <div></div>
        </div>
  </div>
  </div>
  )
}
