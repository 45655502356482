import React from 'react';
//@ts-ignore. @ts-expect-error
import Main from './components/Main';
import { BrowserRouter } from 'react-router-dom'
function App() {
  return (
    <BrowserRouter>
   <Main></Main>
   </BrowserRouter>
  );
}

export default App;
