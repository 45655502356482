export const data=[
    {link:"https://react.dev/learn/managing-state",for:"react"},
    {link:"https://stackoverflow.com/questions/22752116/react-ignores-for-attribute-of-the-label-element",for:"bulljhdhjgdhg"},
    {link:"https://storage.googleapis.com/audio-shit/asdafsa.png",for:"gta"},
    {link:"https://stackoverflow.com/questions/22752116/react-ignores-for-attribute-of-the-label-element",for:"bull"},
    {link:"https://storage.googleapis.com/audio-shit/asdafsa.png",for:"gta"},
    {link:"https://stackoverflow.com/questions/22752116/react-ignores-for-attribute-of-the-label-element",for:"bull"},
    {link:"https://storage.googleapis.com/audio-shit/asdafsa.png",for:"gta"},
    {link:"https://stackoverflow.com/questions/22752116/react-ignores-for-attribute-of-the-label-element",for:"bull"},
    {link:"https://storage.googleapis.com/audio-shit/asdafsa.png",for:"gta"},
    {link:"https://stackoverflow.com/questions/22752116/react-ignores-for-attribute-of-the-label-element",for:"bull"},
]