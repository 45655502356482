import React from 'react'


export default function Done({text,btn,link}:any) {


  if(btn){
    return (
      <div className="login">
      <h1 className='changed' >
       {text}
      </h1>
      <button  > <a href={link} >Home</a></button>
      
      </div>
    )
  }else{
    return (
      <div className="center4">
      <h1 className='changed' >
       {text}
      </h1>
      
      </div>
    )
  }
  
}
