import React from 'react'
import { Link } from 'react-router-dom';
export default function Change() {
  return (
       <div className='login'>
       <h1>haw can we help you qr:</h1> 
       <button><Link to="/url" >change url</Link></button>
       <button><Link to="https://theqrs.store/" >Another One</Link></button>
       <button><Link to="/contact" >contact us </Link></button>
       </div>
  )
}
