import axios from 'axios';
import React, { useState } from 'react';
import useSignIn from 'react-auth-kit/dist/hooks/useSignIn';
export default function Logform(){
  const [email,setEmail]=useState("");
  const signIn=useSignIn();
  const [password,setPassword]=useState("");
  const handlemail=async(event:any) => {
   return setEmail(event.target.value)
  }

  const handlepass=async(event:any)=>{
     return setPassword(event.target.value)
  }
 const onSubmit =async(event:any)=>{
    event.preventDefault();
    axios.post(
     "https://qrs-test.herokuapp.com/authenticate"
    ,{
      email,password
    }
    )
  .then((res:any) => {
      signIn({
        token:res.data.token,
        expiresIn:60,
        tokenType:"Bearer",
        authState:{
          token:res.data.token,
          email:res.data.user.email,
          id:res.data.user.id
        }
      })
      window.location.assign('/');   
     
    }).catch((err:any) => {
      alert("wrong pass G")
    });
  }
  return (
       <form className='login' onSubmit={onSubmit} >
       <h1>Welcome Home Qr:</h1> 
        <div>
        <label htmlFor="email">email:</label>
        <br></br>
        <input 
        type="email" 
        id="email"
        placeholder="Enter email"
        value={email}
        onChange={handlemail}
        required
         />
        </div>
        <div>
        <label htmlFor="pass">pass:</label>
        <br></br>
        <input 
        type="password" 
        id="pass:"
        placeholder="Enter password"
        value={password}
        onChange={handlepass}
         />
        </div>
        <button>log in</button>
       </form>
  )
}
