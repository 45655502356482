import React,{ useRef } from 'react'
import emailjs from '@emailjs/browser';
import { Link } from 'react-router-dom';
export default function Contact() {
  const form = useRef('');
  const sendEmail = (e:any) => {
    e.preventDefault();
    emailjs.sendForm('service_c3j3fci', 'template_mtc37fz', form.current, 'bl5P4iSAq8AQn47lq')
      .then((result) => {
          
      }, (error) => {
          
      });
  };
  return (
    //@ts-ignore. @ts-expect-error
       <form className='login' ref={form} onSubmit={sendEmail} >
       <h1>Haw Can We Help:</h1> 
        <div>
        <label htmlFor="email">email:</label>
        <br></br>
        <input type="email" id="email" name="user_email" placeholder="Enter email" />
        </div>
        <div>
        <label htmlFor="pass">message:</label>
        <br></br>
        <textarea  id="pass"  name="message" placeholder="your message" />
        </div>
        <button type="submit" ><Link to="/sent" >send</Link></button>
       </form>
  )
}
