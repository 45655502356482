import React from 'react'
import { data } from '../data/data'

export default function Links() {
  return (
    
    <div className='login' >
    <div className='center3'>
        {data.map((x)=>{
            return (
        <div className='horizental2'>
          <label htmlFor="url">{x.for}</label>
          <button className='plus1'  onClick={() =>  navigator.clipboard.writeText(x.link)} >copy</button>
       </div>
            )
        })}
    </div>
    </div>
  )
}
